.App-link {
  /*color: #61dafb;*/
}

.font-face-ata {
  font-family: "Aldo",'Courier New', monospace;
}

.join-the-squadron {
  font-size: 18px;
}

.font-large {
  font-size: 68px;
}
